import { Table, TableBody, TableContainer, TableHead, TableRow, type SxProps } from '@mui/material'
import type { ReactNode } from 'react'

export type BackofficeTableProps = {
  columns?: ReactNode
  /**
   * Headers of the table, use one or more TableCellHeader components
   */
  headers: ReactNode
  /**
   * Use one or more TableRow* components
   */
  rows: ReactNode
  sx?: SxProps
}

export function BackofficeTable(props: BackofficeTableProps): JSX.Element {
  const { columns, headers, rows, sx = {} } = props
  return (
    <TableContainer sx={{ ...sx }}>
      <Table>
        {columns ? <colgroup>{columns}</colgroup> : <></>}
        <TableHead>
          <TableRow>{headers}</TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
    </TableContainer>
  )
}
